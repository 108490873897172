import React from 'react';
import styled from "styled-components";
import {useHistory} from "react-router-dom";

const Footer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-top: 1px solid #eaeaea;
    background-color: #224859;
    color: white;
`;

const FooterWrapper = styled.div`
    display: flex;
    max-width: 1200px;
    padding: 64px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1020px) {
        display: block;
    }
`;

const FooterLogo = styled.div`
    img {
        width: 50px;
    }
`;

const FooterMenu = styled.div`
    display: flex;
    @media (max-width: 1020px) {
        display: block;
    }
`;

const FooterMenuList = styled.div`
    padding: 0px 32px 32px 32px;
    .list-title {
        font-weight: bold;
        margin-bottom: 16px;
        color: #c3d66b;
    }
    .list-item {
        margin-bottom: 8px;
    }
    .list-item:hover {
        cursor: pointer;
        color: rgb(142, 166, 181);
    }
    
    .disabled-list-item {
        margin-bottom: 8px;
        color: #ffffffa1;
    }
    
    @media (max-width: 1020px) {
        padding: 0px;
        margin-top: 32px;
    }
`;

const FooterContactInfo = styled.div`
    @media (min-width: 1020px) {
        margin-left: 32px;
        margin-right: 32px;
    }
    @media (max-width: 1020px) {
        margin-top: 32px;
    }
    a {
        color: white;
    }
`;

const FooterSocialLinks = styled.div`
    margin-left: 32px;
    display: inline-flex;
    
    @media (max-width: 1020px) {
        margin-top: 32px;
        margin-left: 0px;
    }
`;

const SocialIcon = styled.a`
    margin-left: 16px;
    margin-right: 16px;
`;

function AppFooter() {

    const history = useHistory();
    
    function href(path: string, external?: boolean) {
        if (external) {
            window.location.replace(path);
        } else {
            history.push(path);
        }
    }
    
    return (
        <Footer>
            <FooterWrapper>
                <FooterLogo>
                    <img onClick={() => href("/")} src="/maranics_logo_part_white.svg" alt={"Maranics"} />
                </FooterLogo>
                <FooterContactInfo>
                    <div>MARANICS © 2024. All rights reserved.</div>
                    <div>E-mail: <a href={"mailto:contact@maranics.com"}>contact@maranics.com</a></div>
                </FooterContactInfo>
                <FooterMenu>
                    <FooterMenuList>
                        <div className="list-title">Why Maranics?</div>
                        <div className="list-item" onClick={() => href("/features")}>Features</div>
                        <div className="list-item" onClick={() => href("/benefits")}>Benefits of our solution</div>
                        <div className="list-item" onClick={() => href("/usecases")}>Use cases</div>
                        <div className="list-item" onClick={() => href("/blog", true)}>Blog</div>
                    </FooterMenuList>
                    <FooterMenuList>
                        <div className="list-title">Resources</div>
                        <div className={"list-item"} onClick={() => href("/privacy")}>Privacy policy</div>
                        <div className="disabled-list-item">Documentation</div>
                        <div className="list-item" onClick={() => href("/tos")}>Terms of service</div>
                        <div className="disabled-list-item">API</div>
                        <div className="disabled-list-item">Library</div>
                        <div className="disabled-list-item">Help enter</div>
                    </FooterMenuList>
                </FooterMenu>
                <FooterSocialLinks>
                    <SocialIcon>
                        <img style={{ opacity: 0.5 }} src={"/images/ic-facebook.svg"} alt={"facebook"}/>
                    </SocialIcon>
                    <SocialIcon style={{ paddingTop: "3px"}}>
                        <img style={{ opacity: 0.5 }} src={"/images/ic-twitter.svg"} alt={"twitter"}/>
                    </SocialIcon>
                    <SocialIcon style={{ paddingTop: "3px"}} href={"https://www.linkedin.com/company/maranics/about/"} target={"_blank"}>
                        <img src={"/images/ic-in.svg"} alt={"linkedin"}/>
                    </SocialIcon>
                </FooterSocialLinks>
            </FooterWrapper>
        </Footer>
    );
}

export default AppFooter;
