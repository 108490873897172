import styled from "styled-components";
import {motion} from "framer-motion";
import {Box} from "@material-ui/core";

export const ContentAnimation = {
    animate: {
        transition: { staggerChildren: 0.1 },
    },
};

export const BlockAnimation = {
    initial: { y: -20, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.7,
            ease: [0.6, -0.05, 0.01, 0.99],
        },
    },
};

export const TitleAnimation = {
    initial: { y: -20, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.7,
            ease: [0.6, -0.05, 0.01, 0.99],
        },
    },
};

export const PageTitle = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 64px;
    text-align: center;
    @media (max-width: 800px) {
        font-size: 40px;
    }
`;


export const ContentBlock = styled(motion.div)`
        max-width: 1056px;
        margin-inline-start: auto;
        margin-inline-end: auto;
        padding-inline-start: 16px;
        padding-inline-end: 16px;
`;

export const FullCenteredContentBlock = styled(motion.div)`
        max-width: 1056px;
        margin-inline-start: auto;
        margin-inline-end: auto;
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        text-align: center;
`;

export const MotoBlock = styled(motion.div)`
        margin-top: 32px;
        margin-bottom: 32px;
        font-size: 20px;
        color: #9dbd3c;
        font-weight: 500;
    `;

export const DoubleBlock = styled(motion.div)`
        display: flex;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 800px) {
            padding: 16px;
            margin-top: 0px;
        }
        @media (max-width: 1200px) {
            display: grid;
            grid-template-areas: "c" "a";
        }
    `;

export const DoubleBlockLeft = styled.div`
        float: left;
        margin: 32px;
        max-width: 548px;
        margin-left: auto;
        margin-right: auto;
        grid-area: ${(props => props.slot ? props.slot : "a")};
        @media (max-width: 800px) {
            padding-left: 8px;
            padding-right: 8px;
        }
    `;

export const DoubleBlockRight = styled.div`
        float: right;
        margin: 32px;
        max-width: 548px;
        margin-left: auto;
        margin-right: auto;
        grid-area: ${(props => props.slot ? props.slot : "c")};
`;

export const DoubleBlockWrapper = styled.div`
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 70%;
            border-radius: 16px;
        }
        @media (max-width: 800px) {
            video {
                height: 400px;
            }
        }
    `;

export const DoubleBlockTitle = styled(motion.div)`
        width: 480px;
        text-transform: uppercase;
        max-width: 90vw;
        @media (max-width: 800px) {
            h1 {
                font-size: 36px;
            }
        }
    `;

export const DoubleBlockSmallerTitle = styled(motion.div)`
        width: 480px;
        text-transform: uppercase;
        max-width: 90vw;
        @media (max-width: 800px) {
            font-size: 24px;
        }
    `;

export const DoubleBlockDescription = styled.div`
        margin-top: 16px;
        width: 480px;
        max-width: 90vw;
        p {
            font-size: 18px;
        }
    `;

export const Spacer = styled.div`
        margin-top: 32px;
`;

export const DoubleSpacer = styled.div`
    margin-top: 64px;
`;

export const PageEndSpacer = styled.div`
    margin-top: 72px;
`;

export const FullBlockImage = styled.img`
        width: 80%;
        border-radius: 16px;
        object-fit: cover;
        margin-top: 32px;
        margin-bottom: 16px;
    `;

export const FeatureBlock = styled(motion.div)`
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        
    `;

export const FeatureBlockTitle = styled.div`
        font-size: 40px;
        margin-top: 32px;
        margin-bottom: 64px;
    `;

export const FeatureGrid = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 64px;
        @media (max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }

    `;

export const FeatureItem = styled.div`
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    `;

export const FeatureItemIcon = styled.div`
        padding-bottom: 32px;
        img {
            width: 64px;
            height: 64px;
        }
    `;

export const FeatureItemTitle = styled.div`
        font-size: 20px;
        font-weight: 500;
        color: #224859;
    `;

export const FeatureItemDescription = styled.div`
        margin-top: 16px;
    `;

export const DoubleBlockList = styled.ul`
    li {
        list-style-image:url('/images/ic-point.svg');
        padding-left: 16px;
        margin-bottom: 16px;
    }
    
`;

export const InnerList = styled.ul`
    margin-top: 16px;
    margin-bottom: 8px;
    list-style-type: initial;
`;

export const BlockButton = styled.div`
    margin-top: 32px;
    background-color: #c3d66b;
    font-size: 20px;
    padding: 16px;
    width: 200px;
    border-radius: 4px;
    text-align: center;
    &:hover {
        cursor: pointer;
        background-color: #cedf80;
    }
    @media (max-width: 800px) {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const QuoteBlock = styled.div`
    background-color: #e1eab5;
    padding: 32px;
`;

export const QuoteContent = styled.div`
    font-size: 24px;
    font-style: italic;
    color: black;
`;

export const QuoteAuthor = styled.div`
    margin-top: 16px;
    font-weight: 500;
    color: #224859;
`;

export const FeatureCard = styled(motion.div)`
    margin-left: auto;
    margin-right: auto;
    max-width: 512px;
    -webkit-box-shadow: 0px 0px 24px -3px rgba(215,215,215,1);
    -moz-box-shadow: 0px 0px 24px -3px rgba(215,215,215,1);
    box-shadow: 0px 0px 24px -3px rgba(215,215,215,1);
    border-radius: 8px;
    padding-top: 24px;
    padding-bottom: 24px;
`;

export const LastFeatureCard = styled(FeatureCard)`
    @media (max-width: 1199px) {
        margin-top: -32px;
    }
`;

export const CardTitleStrip = styled.div`
    color: #224859;
    height: 100px;
    display: inline-flex;
    align-items: center;
    width: 100%;
`;

export const FeatureCardTitleStrip = styled(CardTitleStrip)`
    background-color: #eaf0cc;
`;

export const BenefitsCardTitleStrip = styled(CardTitleStrip)`
    background-color: #f0e9cb;
`;

export const FeatureCardTitle = styled.div`
    margin-left: 32px;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 500;
`;

export const FeatureCardIcon = styled.div`
    margin-left: 32px;
    img {
        height: 48px;
    }
`;

export const FeatureCardDescription = styled.div`
    margin: 24px;
`;

export const PageMoto = styled(motion.div)`
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
    text-align: center;
    padding: 64px;
    p { 
        font-size: 1.3rem;
        font-weight: 300;
    }
`;

export const FeatureCardGrid = styled(motion.div)`
    max-width: 1056px;
    margin-left: auto;
    margin-right: auto;

`;

export const RevenueTable = styled.table`
    font-size: 13px;
    text-align: left;
    border-collapse: collapse;
`;

export const RevenueTableHead = styled.tr`
    td:first-child {
        min-width: 140px;
        max-width: 140px;
    }
    td:not(:first-child) {
        background-color: #224859;
        color: white;
        padding: 8px;
        font-weight: 500;
        min-width: 90px;
        max-width: 90px;
        outline: 1px solid #e1e1e1;
    }
`;

export const RevenueTableRow = styled.tr`
    height: 64px;
    td {
        padding: 16px;
        outline: 1px solid #e1e1e1;
        text-align: center;
    }
    td:first-child {
        background-color: #c3d66b;
        color: #224859;
        font-weight: 500;
        text-align: left;
    }
`;

export const RevenueMaranicsTableRow = styled.tr`
    height: 64px;
    outline: 1px solid #c3d66b;
    td {
        padding: 16px;
        background-color: #edf3d2;
        outline: 1px solid #d5e0a3;
        
        text-align: center;
    }
    td:first-child {
        background-color: white;
        color: #224859;
        font-weight: 500;
        img {
            width: 150px;
        }
    }
`;

export const InlineFlexBox = styled(Box)`
    display: inline-flex;
`;

export const TableCaptionItem = styled(Box)`
    padding: 16px;
    display: inline-flex;
    img {
        height: 18px;
        margin: 18px;
    }
`;

export const RevenueTableBox = styled(Box)`
    overflow-x: auto;
    padding: 8px;
`;