import React from 'react';
import {
    BlockAnimation,
    ContentAnimation,
    DoubleBlockList,
    FeatureCard,
    FeatureCardDescription, FeatureCardGrid,
    FeatureCardIcon, FeatureCardTitle,
    FeatureCardTitleStrip, PageEndSpacer,
    PageTitle,
    Spacer,
} from "../../Shared/styles";
import { motion } from "framer-motion"
import {Typography} from "@material-ui/core";
// @ts-ignore
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
// @ts-ignore
import MetaTags from 'react-meta-tags';

export function Features() {
    
    return (
        <div>
            <MetaTags>
                <title>5 features that will simplify your operations - Maranics</title>
                <meta name="title" content="5 features that will simplify your operations - Maranics" />
                <meta name="description" content="Maranics offer a wide variety of features to simplify your operations. The smart functions of Automated processes, digital flows, documentation, data sharing and multicloud & remote." />
                <meta property="og:title" content="5 features that will simplify your operations - Maranics" />
                <meta property="og:description" content="Maranics offer a wide variety of features to simplify your operations. The smart functions of Automated processes, digital flows, documentation, data sharing and multicloud & remote." />
                <meta property="og:url" content="https://maranics.eu/features" />
            </MetaTags>
            <motion.section exit={{ opacity: 0}}>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={ContentAnimation}
                    className={"content-grid"}
                >
                    <PageTitle>Features</PageTitle>
                    <Spacer />
                    <hr/>
                    <Spacer />
                    <FeatureCardGrid variants={BlockAnimation}>
                        <ResponsiveMasonry columnsCountBreakPoints={{800: 1, 1200: 2}}>
                            <Masonry columnsCount={2} gutter={"32px"}>
                                <FeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/features/ic-process.svg" alt={"process"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>Process automations</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>Maranics operation centric platforms are made for the automation of human processes and interaction with sensors and machines. The platform allows you to:</Typography>
                                        <DoubleBlockList>
                                            <li>Automate human processes through digital flows</li>
                                            <li>Trigger flows based on sensors or other systems</li>
                                            <li>Ensure the users have access to the latest information all the time</li>
                                            <li>Collect user input for improved analytics</li>
                                        </DoubleBlockList>
                                    </FeatureCardDescription>
                                </FeatureCard>
                                <FeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/features/ic-flow.svg" alt={"flow"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>Digital flows</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>Digital flows give you the possibility to integrate real-time data flows among different devices and applications. That's what will help you have your flows cooperating in accordance with reality and not trying to adjust the reality to cooperate with your flows.</Typography>
                                    </FeatureCardDescription>
                                </FeatureCard>
                                <FeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/features/ic-data.svg" alt={"data"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>Data sharing</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>Data is not a one-way street. Be able to easily create and share the data to reduce the time-consuming administrative work and instead focus on your primary operation.</Typography>
                                    </FeatureCardDescription>
                                </FeatureCard>
                                <FeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/features/ic-doc.svg" alt={"doc"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>Documentation</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>Documentation allows you to store all of your documentation in one application. It is easy and quick access to all of your documentation from other OC applications.</Typography>
                                    </FeatureCardDescription>
                                </FeatureCard>
                                <FeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/features/ic-multicloud.svg" alt={"multicloud"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>Multicloud and remote</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>You control the choice that suits you the best for local storage. Is it in your facilities, in the cloud, or why not both? Everything is possible with the convenient help of Multicloud.</Typography>
                                    </FeatureCardDescription>
                                </FeatureCard>
                                <FeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/features/ic-solution.svg" alt={"solution"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>Solution store</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>Will be available later this fall</Typography>
                                    </FeatureCardDescription>
                                </FeatureCard>
                            </Masonry>
                        </ResponsiveMasonry>
                    </FeatureCardGrid>
                    <PageEndSpacer />
                </motion.div>
            </motion.section>
        </div>
    )
}