import React from 'react';
import './App.css';
import AppHeader from "./AppHeader";
import AppContent from './AppContent/AppContent';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import AppFooter from "./AppFooter";

const theme = createMuiTheme({
    typography: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "sans-serif"].join(','),
        body1: {
            fontWeight: 300
        },
        h1: {
            fontSize: "48px"
        },
        h3: {
            fontSize: "36px",
            fontWeight: 300
        }
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <div className="App">
                    <AppHeader/>
                    <AppContent/>
                    <AppFooter/>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
