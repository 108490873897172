import React from 'react';
import {
    BlockAnimation,
    ContentAnimation,
    DoubleSpacer,
    FeatureCard,
    FeatureCardDescription,
    FeatureCardGrid,
    FeatureCardIcon,
    FeatureCardTitle, FeatureCardTitleStrip,
    FullCenteredContentBlock, InlineFlexBox, LastFeatureCard, PageEndSpacer,
    PageTitle, RevenueMaranicsTableRow, RevenueTable, RevenueTableBox, RevenueTableHead, RevenueTableRow,
    Spacer, TableCaptionItem,
} from "../../Shared/styles";
import { motion } from "framer-motion"
import {Box, Typography} from "@material-ui/core";
// @ts-ignore
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
// @ts-ignore
import MetaTags from 'react-meta-tags';

export const GreenMarkCell = () => <td><img src={"/images/benefits/ic-functionality.svg"} alt={"have-func"}/></td>;
export const RedMarkCell = () => <td><img src={"/images/benefits/ic-cross.svg"} alt={"do-not-solve"}/></td>;
export const YellowMarkCell = () => <td><img src={"/images/benefits/ic-tick.svg"} alt={"do-partly"}/></td>;
    
export function Benefits() {
    return (
        <div>
            <MetaTags>
                <title>Benefits of our solution - Maranics</title>
                <meta name="title" content="Benefits of our solution - Maranics" />
                <meta name="description" content="Benefits of using Maranics Operational Center is the high flexibility and scalability, our easy to use digital flows and the core part of the platform the data quality." />
                <meta property="og:title" content="Benefits of our solution - Maranics" />
                <meta property="og:description" content="Benefits of using Maranics Operational Center is the high flexibility and scalability, our easy to use digital flows and the core part of the platform the data quality." />
                <meta property="og:url" content="https://maranics.eu/benefits" />
            </MetaTags>
            <motion.section exit={{ opacity: 0}}>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={ContentAnimation}
                    className={"content-grid"}
                >
                    <PageTitle>Benefits of our solution</PageTitle>
                    <Spacer />
                    <hr/>
                    <Spacer />
                    <FeatureCardGrid variants={BlockAnimation}>
                        <ResponsiveMasonry columnsCountBreakPoints={{800: 1, 1200: 2}}>
                            <Masonry columnsCount={2} gutter={"32px"}>
                                <FeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/benefits/ic-scalability.svg" alt={"process"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>High flexibility and scalability</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>If you grow, we will grow with you. When your business and needs are growing, we will easily scale up to your required precise needs without the large traditional hardware investments.</Typography>
                                    </FeatureCardDescription>
                                </FeatureCard>
                                <FeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/benefits/ic-target.svg" alt={"flow"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>Digital flows</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>All our tools are effortless to learn and require no specific training from the end-user.</Typography>
                                    </FeatureCardDescription>
                                </FeatureCard>
                                <Box />
                                <LastFeatureCard>
                                    <FeatureCardTitleStrip>
                                        <FeatureCardIcon>
                                            <img src="/images/benefits/ic-quality.svg" alt={"data"} />
                                        </FeatureCardIcon>
                                        <FeatureCardTitle>Data quality</FeatureCardTitle>
                                    </FeatureCardTitleStrip>
                                    <FeatureCardDescription>
                                        <Typography>A core part for Maranics is to ensure that the delivered data can be reused in the future. The contextualization of data is a core part of the platform.</Typography>
                                    </FeatureCardDescription>
                                </LastFeatureCard>
                            </Masonry>
                        </ResponsiveMasonry>
                    </FeatureCardGrid>
                    <DoubleSpacer />
                    <FullCenteredContentBlock variants={BlockAnimation}>
                        <RevenueTableBox>
                            <RevenueTable>
                                <RevenueTableHead>
                                    <td/>
                                    <td>Reusable and contextualized data</td>
                                    <td>Humans and machines</td>
                                    <td>Administrative processes and decision making</td>
                                    <td>Operational processes (during operation)</td>
                                    <td>Capturing human inputs realtime</td>
                                    <td>Remote EDGE and hybrid-clouds</td>
                                    <td>Realtime and event based</td>
                                    <td>Handling complexity and agility</td>
                                </RevenueTableHead>
                                <RevenueTableRow>
                                    <td>Paper based</td>
                                    <RedMarkCell />
                                    <RedMarkCell />
                                    <YellowMarkCell />
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                </RevenueTableRow>
                                <RevenueTableRow>
                                    <td>Excel and document based systems</td>
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                    <YellowMarkCell />
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                </RevenueTableRow>
                                <RevenueTableRow>
                                    <td>ERP Systems</td>
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                    <GreenMarkCell />
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                </RevenueTableRow>
                                <RevenueMaranicsTableRow>
                                    <td>
                                        <img src={"/images/benefits/logo.png"} alt={"Maranics"} />
                                    </td>
                                    <GreenMarkCell />
                                    <GreenMarkCell />
                                    <GreenMarkCell />
                                    <GreenMarkCell />
                                    <GreenMarkCell />
                                    <GreenMarkCell />
                                    <GreenMarkCell />
                                    <GreenMarkCell />
                                </RevenueMaranicsTableRow>
                                <RevenueTableRow>
                                    <td>IoT platforms</td>
                                    <YellowMarkCell />
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                    <GreenMarkCell />
                                    <GreenMarkCell />
                                    <YellowMarkCell />
                                </RevenueTableRow>
                                <RevenueTableRow>
                                    <td>Cloud based process softwares</td>
                                    <YellowMarkCell />
                                    <YellowMarkCell />
                                    <GreenMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                    <YellowMarkCell />
                                    <YellowMarkCell />
                                </RevenueTableRow>
                                <RevenueTableRow>
                                    <td>Reporting systems (forms and similar)</td>
                                    <YellowMarkCell />
                                    <YellowMarkCell />
                                    <GreenMarkCell />
                                    <RedMarkCell />
                                    <RedMarkCell />
                                    <YellowMarkCell />
                                    <YellowMarkCell />
                                    <RedMarkCell />
                                </RevenueTableRow>
                            </RevenueTable>
                            <InlineFlexBox>
                                <TableCaptionItem>
                                    <img src={"/images/benefits/ic-functionality.svg"} alt={"have-func"}/>
                                    <p>Have the functionality</p>
                                </TableCaptionItem>
                                <TableCaptionItem>
                                    <img src={"/images/benefits/ic-tick.svg"} alt={"do-partly"}/>
                                    <p>Do the job poorly or only partly</p>
                                </TableCaptionItem>
                                <TableCaptionItem>
                                    <img src={"/images/benefits/ic-cross.svg"} alt={"do-not-solve"}/>
                                    <p>Do not solve it</p>
                                </TableCaptionItem>
                            </InlineFlexBox>
    
                        </RevenueTableBox>
                    </FullCenteredContentBlock>
                    <PageEndSpacer />
                </motion.div>
            </motion.section>
        </div>
    )
}