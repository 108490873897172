import React from 'react';
import {motion} from "framer-motion";
import {BlockAnimation, ContentAnimation, ContentBlock, PageEndSpacer, PageTitle, Spacer} from "../../Shared/styles";
// @ts-ignore
import MetaTags from 'react-meta-tags';

export const Terms = () => {
    return (
        <div>
            <MetaTags>
                <title>Terms of service - Maranics</title>
                <meta name="title" content="Terms of service - Maranics" />
                <meta property="og:url" content="https://maranics.eu/tos" />
            </MetaTags>
            <motion.section exit={{ opacity: 0}}>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={ContentAnimation}
                    className={"content-grid"}
                >
                    <PageTitle>Terms of service</PageTitle>
                    <Spacer />
                    <hr/>
                    <ContentBlock variants={BlockAnimation}>
                        <h3>Valid from 01.01.2022</h3>
                        <br />
                        <h2>1. GENERAL</h2>
                        <p>1.1. These terms of service (the "Terms") always apply, unless otherwise agreed in writing, between Maranics AB, reg.no 559159-9013, and its subsidiaries&nbsp; (together "Maranics"), and every user (the "User") of Maranics&rsquo; software service [SaaS name] (the "Service"). Maranics and the User are hereinafter also referred to jointly as the "Parties" and individually as a "Party".</p>
                        <p>1.2. These Terms regulate Maranics&rsquo; provision of the Service and the Parties' dealings in connection therewith. Through the Terms, Maranics provides the User with a non-exclusive and non-transferable license to access and use the Service.</p>
                        <p>1.3. The Parties enter into an agreement when the User downloads or otherwise accesses the Service and agrees to these terms.</p>
                        <p>1.4. By agreeing to the Terms, the User also approves of Maranics&rsquo; processing of all information and data uploaded by the User to the Service.</p>
                        <h2>2. AMENDMENTS</h2>
                        <p>2.1. Maranics has the right to amend or make additions to the Terms at any time, without first obtaining the User's approval. An updated version of the Terms will be submitted to the User&rsquo;s registered email address.</p>
                        <p>2.2. If the User uses the Service after the amended Terms have entered into force, the User is deemed to have approved the amended Terms.</p>
                        <h2>3. THE USER&rsquo;S COMMITMENTS</h2>
                        <p>3.1. The User warrants that the User is legitimate legal entity. Registrations by "bots" or other automated methods are not permitted and will be terminated by Maranics.</p>
                        <p>3.2. The User undertakes to comply with the at all times applicable instructions, technical specifications and other regulations that Maranics provides for the use of the Service.</p>
                        <p>3.3. The User undertakes to only use the Service for its intended purpose and warrants that the User will not use the Service in breach of the Terms or applicable law or access Maranics&rsquo; private API by means other than those expressly permitted by Maranics.</p>
                        <p>3.4. The User acknowledges that Maranics has the right (but not an obligations) to monitor the User&rsquo;s actions within the Service at any time and that Maranics also has the right to investigate any violation of these Terms according to applicable laws.</p>
                        <p>3.5. Should the User abuse the Service, the User shall indemnify and hold Maranics harmless from any damage that Maranics suffers due to, or in relation to, the abuse.</p>
                        <p>3.6. The User is responsible for all activities carried out by the User or via the User&rsquo;s license to the Service.</p>
                        <p>3.7. The User is solely responsible for all information and material produced by the User within the Service and which the User makes available on or through the Service. The User is also responsible for ensuring that all such material does not infringe the rights of third parties or violates applicable law.</p>
                        <p>3.8. The User also represents that no reviews, comments, feedback, suggestions, ideas, and other submissions disclosed, submitted, or offered to Maranics in connection with the User&rsquo;s use of the Service (collectively the &ldquo;User Submissions&rdquo;), will violate the right of any third party, including without limitation, copyright, trademark, privacy, or other personal or proprietary right(s), and that no User Submissions submitted by the User will cause injury to any person or entity. The User further agrees that no User Submissions submitted by the User will be or contain libelous or otherwise unlawful, threatening, abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any form of &ldquo;spam&rdquo;.</p>
                        <p>3.9. The User undertakes not to give any third party access to the User&rsquo;s license or to sublicense the Service in any way.</p>
                        <p>3.10. In the event that the User suspects or should suspect that third party is using the User&rsquo;s license to the Service, the User is obliged to immediately take measures to limit such party&rsquo;s access to the Service, and to promptly notify Maranics of the occurred event.</p>
                        <p>3.11. The User accepts that Maranics is not responsible for any unauthorized access to and abuse of the Service and for any consequences thereof, which are result of the User's negligence.</p>
                        <p>3.12. The User shall indemnify and hold Maranics harmless in the event that the User&rsquo;s use of the Service would result in Maranics being liable for compensation in relation to third parties.</p>
                        <h2>4. AVAILABILITY OF THE SERVICE</h2>
                        <p>4.1. Maranics&rsquo; objective is to be able to offer high availability to the Service, with the exception of any interruptions for technical- or system maintenance. The Service may however be disturbed by factors and circumstances (e.g. technical disturbances, errors in the User's digital device or network, viruses etc.) that are outside of Maranics&rsquo; control and for which Maranics shall not be responsible.</p>
                        <p>4.2. Maranics reserves the right, when this is required with regard to maintenance, technical or safety reasons, to take measures that may temporarily affect the availability of the Service.</p>
                        <p>4.3. In certain cases, when the use of the Service risks serious damage to Maranics, Maranics is entitled to immediately block the User's access to the Service.</p>
                        <p>4.4. In the event that access to the Service is blocked or restricted in any way, the User will be notified of the measures as soon as possible.</p>
                        <p>4.5. Maranics takes no responsibility for the availability or functionality of Services managed locally, on a private cloud, or on the Edge, by the User. The User is hence responsible for the running environment and the accessibility of the Services under such circumstances.</p>
                        <h2>5. DEFECTS</h2>
                        <p>5.1. The User shall report any observed defects in the Service to Maranics&rsquo; support function, by sending an email to [email address]. The User shall describe and, if necessary, demonstrate how the defect manifests.</p>
                        <p>5.2. Maranics undertakes to remedy any defects in the Service, provided that so can be done without unreasonable cost or inconvenience to Maranics. However, Maranics cannot guarantee that the Service will work flawlessly and continuously.</p>
                        <h2>6. LIMITATION OF LIABILITY AND DISCLAIMER</h2>
                        <p>6.1. The User understands and agrees that the Service is provided without any warranty from Maranics that the Service will be available at all times, will function properly or without disorders, that the use of the Service will cause no harm to the User or third parties, or that paid services, sponsored content, or commercial communications will always be identified as such.</p>
                        <p>6.2. Maranics is not responsible for information that the User documents or the User's actions or conduct in general within the Service.</p>
                        <p>6.3. Maranics is not responsible for interruptions or errors in the Service due to local running environments, private clouds, third-party systems or software, operated by the User or a third party.</p>
                        <p>6.4. Maranics is not liable for damages that are caused by a loss of information for the User.</p>
                        <p>6.5. The User's right to compensation for damages according to the Terms shall under all circumstances be limited to direct damages and to an amount corresponding to the total amount paid by the User for the license to the Service. The limitation of liability also applies in relation to the User's possible liability towards third parties.</p>
                        <p>6.6. A request for compensation from the User must be made to Maranics within a reasonable time from the time the User&rsquo;s suffered damage was discovered or should have been discovered (normally within 30 days). If the request is not made within reasonable time, the User shall be deemed to have forfeited its right to compensation.</p>
                        <h2>7. FEES AND PAYMENT</h2>
                        <p>7.1. Unless otherwise agreed, Maranics shall be entitled to charge the User a [yearly/monthly] fee for the User&rsquo;s license to the Service</p>
                        <p>7.2. In the event of late payment, Maranics may claim interest on overdue payment in accordance with the Swedish Interest Act (Sw: R&auml;ntelagen (1975:635)).</p>
                        <p>7.3. Maranics also has the right to restrict the User&rsquo;s access to the Service until all overdue fees have been paid in full.&nbsp;</p>
                        <h2>8. PROCESSING OF DATA</h2>
                        <p>8.1. The User understands and agrees that by utilizing any third party application in connection with the Service, the User consents to share the User&rsquo;s data with such third party application. To understand how such third party application provider utilizes the User&rsquo;s data the User is referred to the privacy policy of such third party application provider.</p>
                        <p>8.2. Maranics shall process the User's personal data in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46 / EC) and the Swedish law (2018: 218) with supplementary provisions to the EU data protection regulation (jointly referred to as &ldquo;GDPR&rdquo;).</p>
                        <p>8.3. For more information regarding Maranics&rsquo; personal data processing, the User is referred to Maranics&rsquo; privacy policy (as applicable from time to time and found at <a href="/privacy">Privacy policy</a>).</p>
                        <h2>9. FORCE MAJEURE</h2>
                        <p>9.1. A Party shall be exempt from liability for breach of the Terms and/or sanction as a result of failure to fulfill obligations under the Terms due to unforeseen circumstances that hinder or significantly impede or delay such Party&rsquo;s performance. Such circumstances include, but are not limited to, war, terrorist attack, authority regulation, action or omission, labor market conflict (even where the Party does not itself participate in the conflict), blockade, fire, restrictions on energy supply, flood or other natural disaster or accident of greater magnitude or other circumstances outside of a Party&rsquo;s control, which the Party reasonably could not have foreseen and whose consequences the Party could not have reasonably avoided or overcome.</p>
                        <p>9.2. A Party wishing to invoke such a force majeure condition shall without delay inform the counterparty in writing of the occurrence or cessation of the force majeure condition.</p>
                        <h2>10. INTELLECTUAL PROPERTY RIGHTS</h2>
                        <p>10.1. Maranics is the sole owner of all rights, including intellectual property rights to the Service, including but not limited to rights concerning trademarks, trade names, design, text, software, etc., except for certain rights owned by Maranics&rsquo; licensors.</p>
                        <p>10.2. Nothing in the Terms shall be construed as if any of these rights being wholly or partly transferred to the User. Any copying, modification, transfer, licensing and / or other use of the Service other than allowed under the Terms is prohibited.</p>
                        <p>10.3. The User understands that the unauthorized use of Maranics&rsquo; intellectual property rights constitutes a breach of contract and may constitute a criminal action. Maranics has the right to take legal action against the User in the event of such unauthorized use of Maranics&rsquo; intellectual property rights.</p>
                        <p>10.4. Any intellectual property that is created through the Service by the User (&ldquo;User Content&rdquo;) shall accrue to the User. However, the User hereby grants Maranics a global, unlimited, free, eternal and non-revocable license to use and analyze such User Content, in order to further improve the Service and Maranics&rsquo; business.</p>
                        <p>10.5. Similarly, the User agrees that any reporting data collected from the User may be used by Maranics to provide the Service to User, and that as long as the data is anonymized and does not identify the User, Maranics may combine such data with the anonymized data of other companies to provide benchmarking, public reports or to otherwise use it to improve and provide the Service.</p>
                        <p>10.6. The User also grants Maranics a royalty-free, irrevocable, transferable right and license to use all User Submissions. Maranics may freely use the User Submissions in any way, including without limitation, to copy, modify, delete in its entirety, adapt, publish, translate, create derivative works from, and/or sell and/or distribute such User Submissions and/or incorporate such User Submissions into any form, medium, or technology throughout the world. To clarify, User Submissions does not constitute User Content under these Terms.</p>
                        <h2>11. COMMUNICATION</h2>
                        <p>11.1. The User agrees that all communication between the Parties, including but not limited to confirmation, reminder, termination, notices and other information in connection with the Service and/or the Terms, primarily shall be by email between the Parties or such other means as Maranics specifies in the App, the Website or otherwise.</p>
                        <p>11.2. The User undertakes to state the current and valid email address to which the User wishes the communication to be sent when registering the user account. The User further undertakes to immediately notify Maranics of updates regarding the User&rsquo;s contact information.</p>
                        <h2>12. TERM AND TERMINATION</h2>
                        <p>12.1. The Terms enter into force once accepted by the User in accordance with section 1.3 and remains in force for as long as the User uses the Service.</p>
                        <p>12.2. Maranics may terminate the User&rsquo;s access to the Service with immediate effect if the User violates the obligations under the Terms.</p>
                        <h2>13. CHANGES IN THE SERVICE AND SHUTDOWN</h2>
                        <p>13.1. Maranics is entitled at any time, without prior notice, to change the design of the Service and its functions, as well as how the Service is provided.</p>
                        <p>13.2. Maranics also has the right to implement updates, develop, improve and modify the Service to such an extent as Maranics deems necessary or appropriate. Such changes may result in some of the Service' functions being changed, terminated or added.</p>
                        <p>13.3. Maranics also has the right to at any time shut down the Service entirely.</p>
                        <p>13.4. The User shall have the right to terminate its use of the service and demand a refund for the remainder of the User&rsquo;s license period in case of a permanent shutdown of the Service or implemented changes to the Service which are unacceptable to the User.</p>
                        <h2>14. ASSIGNMENT</h2>
                        <p>14.1. The User is not entitled to transfer all or part of the User&rsquo;s rights or obligations under the Terms to a third party.</p>
                        <p>14.2. Maranics may assign its rights and obligations to any subsidiary of Maranics, or to any third party that acquires Maranics or Maranics&rsquo; business.</p>
                        <h2>15. APPLICABLE LAW AND DISPUTES</h2>
                        <p>15.1. Swedish law shall apply to the Service and the Terms.</p>
                        <p>15.2. In case of dispute or disagreement arising out of or in connection with the Service or these Terms, the Parties shall primarily seek to resolve the dispute or disagreement amicably by discussion.</p>
                        <p>15.3. In the event that no solution or agreement can be reached between the Parties, the dispute shall be finally settled by arbitration procedure administered by the Arbitration Institute of the Stockholm Chamber of Commerce (SCC). Rules for Expedited Arbitration shall be applied unless SCC, considering the severity of the case, the value of the dispute and other circumstances, determines that the Arbitration Rules shall apply.</p>
                        <p>15.4. The seat of arbitration shall be Gothenburg. The language to be used in the arbitral proceedings shall be English, unless the Customer is a Swedish company in which case Swedish shall be used.</p>
                        <p>15.5. All arbitral proceedings conducted with reference to this section 15 shall be kept strictly confidential. This confidentiality undertaking shall cover all information disclosed in the course of such arbitral proceedings, as well as any decision or award that is made or declared during the proceedings. Information covered by this confidentiality undertaking may not, in any form, be disclosed to a third party without the written consent of the other Party. A Party shall, however,&nbsp; not be prevented from disclosing such information in order to safeguard in the best possible way its rights against the other Party in connection with the dispute, or if such right exists pursuant to statute, regulation, a decision by an authority or similar.</p>
                        <p>15.6. Notwithstanding the above, Maranics shall have the right to initiate proceedings against the Customer in general Swedish court, with the Gothenburg district court (Sw: G&ouml;teborgs tingsr&auml;tt) as the first instance, in cases of disputes related to unpaid invoices or fees.</p>
                    </ContentBlock>
                    <PageEndSpacer />
                </motion.div>
            </motion.section>
        </div>
    );
};