import React from 'react';
import {
    BlockAnimation,
    ContentAnimation,
    DoubleBlock, DoubleBlockDescription,
    DoubleBlockLeft, DoubleBlockList, DoubleBlockRight, DoubleBlockSmallerTitle,
    DoubleBlockWrapper, DoubleSpacer, FullCenteredContentBlock,
    PageTitle, QuoteAuthor, QuoteBlock, QuoteContent,
    Spacer, TitleAnimation
} from "../../Shared/styles";
import { motion } from "framer-motion"
import {Typography} from "@material-ui/core";
// @ts-ignore
import MetaTags from 'react-meta-tags';

export function Usecases() {
    return (
        <div>
            <MetaTags>
                <title>Use cases Maranics solves for your operations - Maranics</title>
                <meta name="title" content="Use cases Maranics solves for your operations" />
                <meta name="description" content="Maranics can solve wide variety of use cases for your marine operations. For example inspections, incident reporting, operational procedures and much more." />
                <meta property="og:title" content="Use cases Maranics solves for your operations" />
                <meta property="og:description" content="Maranics can solve wide variety of use cases for your marine operations. For example inspections, incident reporting, operational procedures and much more." />
                <meta property="og:url" content="https://maranics.eu/usecases" />
            </MetaTags>
            <motion.section exit={{ opacity: 0}}>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={ContentAnimation}
                    className={"content-grid"}
                >
                    <PageTitle>Use cases</PageTitle>
                    <Spacer />
                    <hr/>
                    <DoubleBlock variants={BlockAnimation}>
                        <DoubleBlockLeft>
                            <DoubleBlockWrapper>
                                <img src="/images/usecases/surveyor-inspection-ship-repair-from-tablet.png" alt={"surveyor-inspection-ship-repair-from-tablet"} />
                            </DoubleBlockWrapper>
                        </DoubleBlockLeft>
                        <DoubleBlockRight>
                            <DoubleBlockWrapper>
                                <div>
                                    <DoubleBlockSmallerTitle variants={TitleAnimation}>
                                        <Typography variant={"h3"}>Example of use cases we can solve for you</Typography>
                                    </DoubleBlockSmallerTitle>
                                    <Spacer />
                                    <DoubleBlockDescription>
                                        <DoubleBlockList>
                                            <li>Operational procedures</li>
                                            <li>Arrival, departure, best practice</li>
                                            <li>Incident reporting</li>
                                            <li>Inspections</li>
                                            <li>Alert management</li>
                                        </DoubleBlockList>
                                    </DoubleBlockDescription>
                                </div>
                            </DoubleBlockWrapper>
                        </DoubleBlockRight>
                    </DoubleBlock>
                    <DoubleBlock variants={BlockAnimation}>
                        <DoubleBlockLeft>
                            <DoubleBlockWrapper>
                                <img src="/images/usecases/crane-lifting-container.png" alt={"crane-lifting-container"} />
                            </DoubleBlockWrapper>
                        </DoubleBlockLeft>
                        <DoubleBlockRight>
                            <DoubleBlockWrapper>
                                <div>
                                    <DoubleBlockSmallerTitle variants={TitleAnimation}>
                                        <Typography variant={"h3"}>Maritime</Typography>
                                    </DoubleBlockSmallerTitle>
                                    <Spacer />
                                    <DoubleBlockDescription>
                                        <DoubleBlockList>
                                            <li>Fire door inspections</li>
                                            <li>Container loading quality assurance</li>
                                            <li>JRCC emergency radio monitoring with Heimdall</li>
                                            <li>Collection of reusable machinery operational data</li>
                                        </DoubleBlockList>
                                    </DoubleBlockDescription>
                                </div>
                            </DoubleBlockWrapper>
                        </DoubleBlockRight>
                    </DoubleBlock>
                    <DoubleSpacer />
                    <QuoteBlock>
                        <FullCenteredContentBlock className="centered" variants={BlockAnimation}>
                            <QuoteContent>
                                “Before I started to work with Maranics, I did not realise how poor the quality of the data we dealt with really was when it comes to operational data.”
                            </QuoteContent>
                            <QuoteAuthor>
                                ― Manager in Shipping
                            </QuoteAuthor>
                        </FullCenteredContentBlock>
                    </QuoteBlock>
                </motion.div>
            </motion.section>
        </div>
    )
}