import React, {useRef, useState} from 'react';
import { motion } from "framer-motion"
import {
    BlockAnimation, BlockButton,
    ContentAnimation,
    DoubleBlock,
    DoubleBlockDescription, DoubleBlockLeft,
    DoubleBlockRight,
    DoubleBlockSmallerTitle,
    DoubleBlockTitle, DoubleBlockWrapper, DoubleSpacer, FeatureBlock, FeatureBlockTitle, FeatureGrid,
    FeatureItem,
    FeatureItemDescription,
    FeatureItemIcon, FeatureItemTitle,
    FullBlockImage, FullCenteredContentBlock, MotoBlock, QuoteAuthor, QuoteBlock, QuoteContent, Spacer, TitleAnimation
} from "../../Shared/styles";
import VisibilitySensor from "react-visibility-sensor";
import {ContactDialog} from "./ContactDialog/ContactDialog";
import {Typography} from "@material-ui/core";
// @ts-ignore
import MetaTags from 'react-meta-tags';

function Main() {
    
    const platformVideoRef = useRef(null);
    const [playPlatformVideo, setPlayPlatformVideo] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false); 
    
    function platformVideoVisibilityChanged(isVisible: boolean) {
        if (isVisible && !playPlatformVideo) {
            setPlayPlatformVideo(true);
            
            if (platformVideoRef && platformVideoRef.current) {
                // @ts-ignore
                platformVideoRef.current.play();
            }
        }
    }
    
    return (
        <div>
            <MetaTags>
                <title>Maranics - Home of Digitalization in the Maritime Industry</title>
                <meta name="title" content="Maranics - Home of Digitalization in the Maritime Industry" />
                <meta property="og:title" content="Maranics - Home of Digitalization in the Maritime Industry" />
                <meta name="description" content="Digitalization happens faster than expected in the maritime industry. Maranics is your strategic operational partner in development and implementation of your digital business." />
                <meta property="og:description" content="Citizens of distant epochs stirred by starlight finite but unbounded muse about rings of Uranus at the edge of forever." />
            </MetaTags>
            <motion.section exit={{ opacity: 0}}>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={ContentAnimation}
                    className={"content-grid"}
                >
                    <DoubleBlock variants={BlockAnimation}>
                        <DoubleBlockLeft>
                            <DoubleBlockWrapper>
                                <div>
                                    <MotoBlock>
                                        Collect data while working instead of<br/>working to collect data
                                    </MotoBlock>
                                    <DoubleBlockTitle variants={TitleAnimation}>
                                        <Typography variant={"h1"}>Reduce work to increase quality</Typography>
                                    </DoubleBlockTitle>
                                    <DoubleBlockDescription>
                                        <Typography>Replacing traditional checklists and manuals with digital OC by Maranics will reduce the workload for your personnel and, at the same time, increase the quality of your processes and data.</Typography>
                                    </DoubleBlockDescription>
                                    <BlockButton onClick={() => setShowContactModal(true)}>
                                        Contact us
                                    </BlockButton>
                                </div>
                            </DoubleBlockWrapper>
                        </DoubleBlockLeft>
                        <DoubleBlockRight style={{ marginTop: "0px", marginBottom: "0px"}}>
                            <DoubleBlockWrapper>
                                {/*<img src="/images/maranics_banner.png" alt={"Maranics Banner"} />*/}
                                <video height={600} autoPlay loop muted playsInline>
                                    <source src="/images/Maranics_App_Phone_02_web.mp4" type="video/mp4"/>
                                </video>
                            </DoubleBlockWrapper>
                        </DoubleBlockRight>
                    </DoubleBlock>
                    <Spacer/>
                    <hr/>
                    <Spacer/>
                    <FullCenteredContentBlock className="centered">
                        <motion.div variants={BlockAnimation}>
                            <FullBlockImage className="block-full-image" src="/images/operation-banner.jpg" alt={"operation-banner"}/>
                        </motion.div>
                    </FullCenteredContentBlock>
                    <Spacer />
                    <QuoteBlock>
                        <FullCenteredContentBlock className="centered" variants={BlockAnimation}>
                            <QuoteContent>
                                “The volume and complexity of what we know have exceeded our ability to deliver its benefits correctly, safely, or reliably.”
                            </QuoteContent>
                            <QuoteAuthor>
                                ― Atul Gawande, The Checklist Manifesto: How to Get Things Right
                            </QuoteAuthor>
                        </FullCenteredContentBlock>
                    </QuoteBlock>
                    <DoubleSpacer />
                    <FeatureBlock variants={BlockAnimation}>
                        <FeatureBlockTitle>
                            Key features
                        </FeatureBlockTitle>
                        <FeatureGrid>
                            <FeatureItem>
                                <FeatureItemIcon>
                                    <img src="/images/key-features/ic-mobile.svg" alt={"Mobile"} />
                                </FeatureItemIcon>
                                <FeatureItemTitle>
                                    Mobile first
                                </FeatureItemTitle>
                                <FeatureItemDescription>
                                    <Typography>Our product is device-independent. It will run on all modern browsers and will feel like a native application.</Typography>
                                </FeatureItemDescription>
                            </FeatureItem>
                            <FeatureItem>
                                <FeatureItemIcon>
                                    <img src="/images/key-features/ic-cloude.svg" alt={"Data"} />
                                </FeatureItemIcon>
                                <FeatureItemTitle>
                                    Shared data model
                                </FeatureItemTitle>
                                <FeatureItemDescription>
                                    <Typography>Your data will be shared across all applications thus, preventing data duplication and enabling you to manage your data in a central place.</Typography>
                                </FeatureItemDescription>
                            </FeatureItem>
                            <FeatureItem>
                                <FeatureItemIcon>
                                    <img src="/images/key-features/ic-sync.svg" alt={"Cloud"}/>
                                </FeatureItemIcon>
                                <FeatureItemTitle>
                                    Hybrid cloud
                                </FeatureItemTitle>
                                <FeatureItemDescription>
                                    <Typography>The platform can either be delivered as full SaaS in the cloud or deployed in hybrid and private cloud. This makes it possible to use it where security and privacy are of concern.</Typography>
                                </FeatureItemDescription>
                            </FeatureItem>
                            <FeatureItem>
                                <FeatureItemIcon>
                                    <img src="/images/key-features/ic-workflow.svg" alt={"Workflow"} />
                                </FeatureItemIcon>
                                <FeatureItemTitle>
                                    Workflows that work
                                </FeatureItemTitle>
                                <FeatureItemDescription>
                                    <Typography>The concept of micro workflows in the platform makes it possible to create workflows that work more like reality.</Typography>
                                </FeatureItemDescription>
                            </FeatureItem>
                            <FeatureItem>
                                <FeatureItemIcon>
                                    <img src="/images/key-features/ic-api.svg" alt={"API"} />
                                </FeatureItemIcon>
                                <FeatureItemTitle>
                                    API integration
                                </FeatureItemTitle>
                                <FeatureItemDescription>
                                    <Typography>The platform has extensive API’s and can therefore be easily integrated with other systems.</Typography>
                                </FeatureItemDescription>
                            </FeatureItem>
                            <FeatureItem>
                                <FeatureItemIcon>
                                    <img src="/images/key-features/ic-remote.svg" alt={"Remote"} />
                                </FeatureItemIcon>
                                <FeatureItemTitle>
                                    Remote environments
                                </FeatureItemTitle>
                                <FeatureItemDescription>
                                    <Typography>The platform has strong EDGE capabilities and can deploy on servers where stable connectivity is of concern. An example of where it could be used is remote factories, ships, offshore installations, or even hospitals that need to operate even when connectivity is down.</Typography>
                                </FeatureItemDescription>
                            </FeatureItem>
                        </FeatureGrid>
                    </FeatureBlock>
                    <DoubleSpacer />
                    <hr/>
                    <DoubleBlock variants={BlockAnimation}>
                        <DoubleBlockLeft>
                            <DoubleBlockWrapper>
                                <div>
                                    <DoubleBlockSmallerTitle variants={TitleAnimation}>
                                        <Typography variant={"h3"}>You have the knowledge<br/>We have the platform</Typography>
                                    </DoubleBlockSmallerTitle>
                                    <DoubleBlockDescription>
                                        <Typography>Maranics OC is not monolith software. Instead, it is a platform that makes it easy to transform your traditional business into a data-driven platform by combining standalone apps into workflows based on your business rules. Our platform suits everyone from a small company to a large enterprise who want to deliver their users a quality digital experience within their processes.</Typography>
                                    </DoubleBlockDescription>
                                </div>
                            </DoubleBlockWrapper>
                        </DoubleBlockLeft>
                        <DoubleBlockRight>
                            <DoubleBlockWrapper>
                                <img style={{ width: "280px" }} src="/images/platform@3x.png" alt={"Maranics Banner"} />
                            </DoubleBlockWrapper>
                        </DoubleBlockRight>
                    </DoubleBlock>
                    <hr/>
                    <DoubleBlock variants={BlockAnimation}>
                        <DoubleBlockLeft slot={"c"}>
                            <VisibilitySensor onChange={(isVisible) => platformVideoVisibilityChanged(isVisible)}>
                                <DoubleBlockWrapper>
                                    <video style={{ width: "90%" }} muted loop playsInline ref={platformVideoRef}>
                                        <source src="/images/platform_animation_comp.mp4" type="video/mp4"/>
                                    </video>
                                </DoubleBlockWrapper>
                            </VisibilitySensor>
                        </DoubleBlockLeft>
                        <DoubleBlockRight slot={"a"}>
                            <DoubleBlockWrapper>
                                <div>
                                    <DoubleBlockSmallerTitle variants={TitleAnimation}>
                                        <Typography variant={"h3"}>Easy to automate and integrate</Typography>
                                    </DoubleBlockSmallerTitle>
                                    <DoubleBlockDescription>
                                        <Typography>Maranics platform is made for automation of human processes and for interaction with sensors, machines and other systems. Our API Management and low-code Workflow Builder make all automations easy whenever it involves platform applications or requires connections to third-party systems or hardware.</Typography>
                                    </DoubleBlockDescription>
                                </div>
                            </DoubleBlockWrapper>
                        </DoubleBlockRight>
                    </DoubleBlock>
                </motion.div>
                <ContactDialog open={showContactModal} onClose={() => setShowContactModal(false)} />
            </motion.section>
        </div>
    );
}

export default Main;
