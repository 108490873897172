import { Dialog, Paper} from "@material-ui/core";
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import styled from "styled-components";
export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
}

const ResponsivePaper = styled(Paper)`
    @media (max-width: 800px) {
        width: 100%;
    }
    @media (min-width: 800px) {
        min-width: 600px;
    }
`;

const iframe = '<iframe style="border: 0; width: 100%" src="https://form.typeform.com/to/zHnMFt4g?typeform-medium=embed-snippet" width="600" height="600" />';
function getIframe() {
    return {
        __html: iframe
    }    
}

export function ContactDialog(props: SimpleDialogProps) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };
    
    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} style={{ marginTop: "80px" }} PaperComponent={ResponsivePaper}>
            { open && [
                <div onClick={() => props.onClose()} style={{ textAlign: "right", color: "lightgray", paddingTop: "8px", paddingRight: "8px" }}>
                    <CloseIcon style={{ cursor: "pointer" }} />
                </div>,
                <div dangerouslySetInnerHTML={getIframe()} />
            ]}
        </Dialog>
    );
}