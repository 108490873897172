import React, {useEffect} from 'react';
import { Switch, Route, useLocation } from "react-router-dom";
import Main from "./Main/Main";
import {Features} from "./Features/Features";
import { AnimatePresence } from "framer-motion";
import {Benefits} from "./Benefits/Benefits";
import {Usecases} from "./Usecases/Usecases";
import {Promo} from "./Promo/Promo";
import {Privacy} from "./Privacy/Privacy";
import {Terms} from "./Terms/Terms";

function AppContent() {
    const location = useLocation();

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth"});
    }, [location]);
    
    return (
        <div className={"app-content"}>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route path={"/features"} exact>
                        <Features />
                    </Route>
                    <Route path={"/benefits"} exact>
                        <Benefits />
                    </Route>
                    <Route path={"/usecases"} exact>
                        <Usecases />
                    </Route>
                    <Route path={"/promo"} exact>
                        <Promo />
                    </Route>
                    <Route path={"/privacy"} exact>
                        <Privacy />
                    </Route>
                    <Route path={"/tos"} exact>
                        <Terms />
                    </Route>
                    <Route path={"/"}>
                        <Main/>
                    </Route>
                </Switch>
            </AnimatePresence>
        </div>
    );
}

export default AppContent;
