import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ChevronDown from "./chevron-down-outline.svg";
import MenuIcon from "./menu-outline.svg";

const Header = styled.header`
    top: 0;
    left: auto;
    right: 0;
    position: fixed;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
    border-bottom: 1px solid rgb(236, 236, 236);
    background-color: white;
    z-index: 9999;
    height: 84px;
`;

const HeaderWrapper = styled.div`
    width: 100vw;
    display: flex;
    position: relative;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    height: 84px;
        
    @media (max-width: 1300px) {
        padding-left: 32px;
        padding-right: 32px;
        width: 90%;
    }
`;

const HeaderLogo = styled.div`
    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        width: 40px;
        height: 40px;
        margin-right: 8px;
    }
    &:hover {
        cursor: pointer;
        opacity: 0.9; 
    }
`;

const HeaderSpacer = styled.div`
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
`;

const HeaderTitleList = styled.div`
    display: flex;
    padding: 8px;
    font-size: 16px;
    font-weight: 300;
    margin-left: auto;
    @media (max-width: 800px) {
        display: none;
    }
`;

const TitleListItem = styled.div`
    display: inline-flex;
    padding: 16px;
    .title-list-item-dropdown {
        display: none;
        position: absolute;
        background-color: #ffffff;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(172, 172, 172, 0.2);
        padding: 16px 16px;
        z-index: 1;
        transform: translateX(-40px);
        margin-top: 24px;
        border: 1px solid #ececec;
        border-radius: 8px;
    }
    .dropdown-item {
        margin: 16px;
    }
    .dropdown-item:hover {
        color: rgb(25, 98, 141);
    }
    
    @media(hover: hover) and (pointer: fine) {
        &:hover {
            cursor: pointer;
        }

        &:hover .title-list-item-dropdown {
            display: block;
        }
    }
`;

const HeaderIconContainer = styled.div`
    margin: 2px;
    margin-left: 4px;
`;
const HeaderIcon = styled.img`
    width: 16px;
`;

const HeaderMenuButton = styled.div`
    margin-right: 16px;
    
    @media (min-width: 801px) {
        display: none;
    }
    
    &:hover, &:active {
        cursor: pointer;
    }
    
    .header-mobile-menu {
        height: 400px;
        background-color: white;
        position: absolute;
        right: 0;
        left: 0;
        box-shadow: 0px 20px 20px 0px #d4d4d4;
    }
    
    .header-mobile-menu.closed {
        display: none;
    }
   
    
    @media(hover: hover) and (pointer: fine) {
        &:hover {
            cursor: pointer;
        }

        &:hover .header-mobile-menu {
            display: block !important;
        }
    }
    
`;

const HeaderMenuButtonIcon = styled.img`
    width: 32px;
`;

const HeaderMenuList = styled.div`
    .list-title {
        font-weight: bold;
        margin-bottom: 16px;
    }
    .list-item {
        margin-top: 32px;
    }
    .list-item.intended {
        margin-left: 32px;
    }
    .list-item:hover {
        cursor: pointer;
        color: rgb(25, 98, 141);
    }
    margin: 72px;
    font-size: 24px;
`;

function AppHeader() {

    const history = useHistory();
    const [ mobileMenuOpened, toggleMobileMenu ] = useState<boolean>(false);
    function href(path: string, external?: boolean) {
        toggleMobileMenu(false);
        if (external) {
            window.location.replace(path);
        } else {
            history.push(path);
        }
    }
    
    return (
        <Header>
            <HeaderWrapper>
                <HeaderLogo onClick={() => href("/")}>
                    <div>
                        <img src="/maranics_logo_part.svg" alt={""} />
                        <p className={"maranics-logo-sign"}>MARANICS</p>
                    </div>
                </HeaderLogo>
                <HeaderSpacer />
                <HeaderTitleList >
                    <TitleListItem onClick={() => href("/")}><strong>Home</strong></TitleListItem>
                    <TitleListItem>
                        Why Maranics?
                        <HeaderIconContainer>
                            <HeaderIcon src={ChevronDown} alt={"down"}/>
                        </HeaderIconContainer>
                        <div className="title-list-item-dropdown">
                            <div className="dropdown-item" onClick={() => href("/features")}>Features</div>
                            <div className="dropdown-item" onClick={() => href("/benefits")}>Benefits of our solution</div>
                        </div>
                    </TitleListItem>
                    <TitleListItem onClick={() => href("/usecases")}>Use cases</TitleListItem>
                    <TitleListItem onClick={() => href("/blog", true)}>Blog</TitleListItem>
                </HeaderTitleList>
                <HeaderMenuButton>
                    <div onClick={() => toggleMobileMenu(!mobileMenuOpened)}>
                        <HeaderMenuButtonIcon src={MenuIcon} />
                    </div>
                    <div className={`header-mobile-menu ${!mobileMenuOpened && "closed"}`}>
                        <HeaderMenuList>
                            <div className="list-title">Why Maranics?</div>
                            <div className="list-item intended" onClick={() => href("/features")}>Features</div>
                            <div className="list-item intended" onClick={() => href("/benefits")}>Benefits of our solution</div>
                            <div className="list-item" onClick={() => href("/usecases")}>Use cases</div>
                            <div className="list-item" onClick={() => href("/blog", true)}>Blog</div>
                        </HeaderMenuList>
                    </div>

                </HeaderMenuButton>
            </HeaderWrapper>
        </Header>
    );
}

export default AppHeader;
