import React from 'react';
import {
    ContentAnimation,
    FullCenteredContentBlock,
    Spacer,
} from "../../Shared/styles";
import { motion } from "framer-motion"
// @ts-ignore
import MetaTags from 'react-meta-tags';

export function Promo() {
    return (
        <div>
            <MetaTags>
                <title>Promo - Maranics</title>
                <meta name="title" content="Promo - Maranics"/>
                <meta name="description" content=""/>
                <meta property="og:title" content="Promo - Maranics"/>
                <meta property="og:description" content=""/>
                <meta property="og:url" content="https://maranics.eu/investor-video"/>
            </MetaTags>
            <motion.section exit={{opacity: 0}}>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={ContentAnimation}
                    className={"content-grid"}
                >
                    <Spacer/>
                    <FullCenteredContentBlock style={{minHeight: "calc(100vh - 200px)"}}>
                        <video
                            poster={"/images/promo-poster.jpg"}
                            style={{width: "90%"}}
                            playsInline
                            controls
                        >
                            <source
                                src="https://drive.google.com/u/0/uc?id=1SAm61MaysCIqaDn3fUpJfmq3xS0QWmjr&export=download#.mp4"
                                type="video/mp4"/>
                        </video>
                    </FullCenteredContentBlock>
                    <Spacer/>
                </motion.div>
            </motion.section>
        </div>
    )
}